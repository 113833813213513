.disabled-link {
	pointer-events: none;
}

.title-text {
	font-weight: 700;
	font-size: 1.5rem;
	margin-bottom: 0.2rem;
}

.subtitle-text {
	font-weight: 400;
	font-size: 0.85rem;
	margin-block-start: 0.4em;
}

.button-full-width {
	width: 100% !important;
}

.consent-step-image {
	width: 5.25rem;
	height: 5.25rem;
	float: left;
}

.consent-desktop-image {
	width: 90%;
	margin: auto;
	margin-top: 1.5vh;
	margin-bottom: 1.5vh;
}

.consent-step-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 6rem;
	margin-top: 1rem;
	font-weight: 400;
	font-size: 1.2rem;
}

/* Extra Small screens - styling for screen with a max width of 600px */
@media only screen and (max-width: 344px) {
	.consent-step-text {
		font-size: 0.77rem !important;
	}
}

/* Small screens - styling for screen with a max width of 700px */
@media only screen and (max-width: 700px) {
	.consent-paper {
		width: 100% !important;
	}
}

/* Medium screens - styling for screen between 700-1200px */
@media only screen and (min-width: 700px) and (max-width: 1200px) {
	.consent-paper {
		width: 75% !important;
	}

	.title-text {
		text-align: center !important;
	}

	.subtitle-text {
		text-align: center !important;
	}
}

/* Large screens - styling for screen above 1200px */
@media only screen and (min-width: 1200px) {
	.consent-paper {
		width: 75% !important;
	}

	.title-text {
		text-align: center !important;
		font-size: 1.85rem !important;
	}

	.subtitle-text {
		text-align: center !important;
		font-size: 1rem !important;
	}
}
